.blockWrapper {
  margin: 0 10px 10px 10px;
  position: relative;
  width: 1170px;
  border: 2px solid white;
  border-radius: 30px;
  height: 420px;
}
.flight {
  background-color: #738fa2;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
.blockTitle {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 2%;
  left: 160px;
  width: 1010px;
}
.title {
  color: black;
  background-color: #dfe4e8;
  font-size: 11px;
  width: 110px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flightBlock {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  max-height: 400px;
  position: relative;
}
.selectBlock {
  position: absolute;
  left: 160px;
  display: flex;
  justify-content: space-between;
  width: 1010px;
  max-height: 400px;
  /* overflow-y: scroll; */
}
.blockContent {
  margin-top: 70px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 330px;
  /* overflow-x: auto; */
}
.titleBtn {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(110, 109, 109);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.addBtnWrapper {
  position: absolute;
  /* bottom: -35px;
  right: 40%; */
  display: flex;
}
.deleteBtn {
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
.flightCPPWrapper {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: space-between;
}