.startButton {
  background-color: white;
  min-height: 130px;
  min-width: 300px;
  border: none;
  color: black;
  font-size: 150%;
  white-space: pre;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.startButton:hover {
  background-color: rgb(200, 200, 209);
  border-color: rgb(200, 200, 209);
}
.startButton:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
}
.add {
  min-height: 30px;
  min-width: 30px;
  font-size: 100%;
}
.redSquareButton {
  background-color: #d32f2f;
  min-height: 80px;
  min-width: 150px;
  border: none;
  color: white;
  font-size: 130%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.redSquareButton:hover {
  background-color: #ab2424;
  border-color: #a02222;
}
.redSquareButton:disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}