.blockContent {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  /* justify-content: space-between;
  height: 200px; */
}
.checkboxWrapper {
  min-height: 40px;
  display: flex;
  margin-left: 40px;
}
