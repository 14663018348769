.delimiterWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.delimiterTitle {
  font-weight: bold;
  font-size: xx-large;
  margin-right: 20px;
  margin-left: 20px;
}
