.legend {
  width: 40px;
  height: 12px;
  margin-right: 5px;
}
.ohvatLegend {
  width: 40px;
  height: 3px;
  margin-right: 5px;
  position: relative;
}
.ohvatLegend::before {
  content: '';
  background: rgb(225, 5, 0);
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  left: 42%;
  top: -100%;
  border-radius: 50%;
}

.legendWrapper {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #808080;
  font-size: 0.8em;
}
.lenendsBox {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 5px;
  flex-wrap: wrap;
  max-width: 1000px;
}
@media screen and (max-width: 1500px) {
  .wrapper { zoom: 130%; }
}
@media screen and (max-width: 1500px) {
  .wrapper{ padding-top: 5px }
}