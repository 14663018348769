.blockWrapper {
  position: relative;
  width: 1450px;
  border: 2px solid grey;
  border-radius: 30px;
  font-size: 0.8rem;
  margin: 10px auto;
}
.tableWarningWrapper {
  position: absolute;
  top: -25%;
  left: 30%;
}
.blockTitleMedia {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 3%;
}
.blockTitleBgt {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 11%;
}

.blockTitleImpress {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 21%;
}
.blockTitleCPM {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 31.5%;
}
.blockTitleDays {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 51%;
}
.blockTitleFreq {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 59%;
}
.blockTitleTsaPr {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 72%;
}
.blockTitleTsaTh {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 82%;
}
.blockTitleTRP {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 92%;
}
.blockTitleTargetSoc {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 38%;
}
.blockTitleTargetDr {
  color: black;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 44%;
}
.blockContent {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  /* overflow-y: scroll; */
  margin-right: 17px;
  position: relative;
}
.item {
  display: flex;
  justify-content: flex-end;
  min-height: 30px;
}
.checkboxWrapper {
  width: 105px;
  display: flex;
}
.targetCheckboxWrapper {
  display: flex;
  position: relative;
}
.targetCheckboxWrapper::before {
  content: '...';
  width: 24px;
  position: absolute;
  top: -25px;
  left: -28px;
  font-size: 40px;
  color: #e6bbbb;
}
.editableSpanWrapper {
  position: relative;
}
.isBlocked {
  user-select: none;
  pointer-events: none;
}
.editableSpanWrapper::before {
  content: '.';
  width: 24px;
  position: absolute;
  top: -25px;
  left: -16px;
  font-size: 40px;
  color: #e6bbbb;
}
.digitalGroupCheckboxWrapper {
  position: absolute;
  top: 60.5%;
}
.oonGroupCheckboxWrapper {
  position: absolute;
  top: 26%;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
.text_muted_for_checkbox {
  color: #868e96;
  pointer-events: auto;
  user-select: auto;
}
.totalWrapper {
  display: flex;
  align-items: center;
}
.totalCell {
  margin-left: 10px;
  padding: 0.1rem 1.1rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 92px;
  text-align: center;
}
/* @media screen and (max-width: 1450px) {
  .blockWrapper {
    width: 1350px;
  }
}
@media screen and (max-width: 1350px) {
  .blockWrapper {
    width: 1250px;
  }
}
@media screen and (max-width: 1250px) {
  .blockWrapper {
    width: 1150px;
  }
} */
