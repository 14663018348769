.legend {
  width: 40px;
  height: 3px;
  margin-right: 5px;
}
.legendWrapperNew {
  width: 49px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.audioLegend {
  position: absolute;
  top: 89%;
  left: 32%
 }
 .radioLegend {
  position: absolute;
  top: 89%;
  left: 43%
 }
 .audioRadioLegend {
  position: absolute;
  top: 89%;
  left: 53% 
 }
@media screen and (max-width: 1500px) {
  .wrapper { zoom: 130%; }
  .legendWrapperNew {
    width: 65px;
    height: 25px;
  }
  .legend {
    width: 60px;
    height: 3.2px;
  }
  .audioLegend {
    left: 26%
   }
   .radioLegend {
    left: 41%
   }
   .audioRadioLegend {
    left: 54% 
   }
}

