.step3Wrapper {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  margin: 10px 30px 0px 30px;
}
.flightBlock {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.flight {
  background: linear-gradient(to top left, #356686 70%, #d1e2ed);
  width: 150px;
  height: 90px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
}
.card {
  width: 100px;
  height: 90px;
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%); */
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.bottomBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}
.btnBlock {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 700px;
  position: relative;
}
.bottomCard {
  font-size: 1.7em;
  font-weight: bold;
  width: 100%;
  display: flex;
  /* flex-direction: row;
  justify-content: space-evenly; */
  margin-left: 50px;
  margin-top: 20px;
}
.bottomDelimiter {
  width: 1000px;
  height: 5px;
  background: linear-gradient(to right, #356686, #d1e2ed 70%);
  margin-left: 30px;
}
.TABlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 370px;
  padding-left: 20px;
  width: 45%;
}
.generalInputDataBlock {
  background: linear-gradient(to right, #356686, #d1e2ed 70%);
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 1130px;
  min-height: 80px;
}
.geoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 340px;
  padding-right: 20px;
  width: 45%;
}
.TA {
  color: rgb(225, 5, 0);
  font-size: 2rem;
  font-weight: bold;
}
/* .loadingBtnsWrapper {
  position: absolute;
  left: 90%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 220px;
  background-color: white;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingBtns {
  border: 4px dotted grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 80%;
} */

.customLegendWrapper {
  width: 500px;
  margin-left: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.tableTitle {
  width: 500px;
  margin-left: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  color: #595959;
  font-size: 120%;
}
.LegendItemWrapper {
  min-width: 100px;
  display: flex;
  align-items: center;
  font-size: 10px;
  margin: 5px;
}
.legend {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
