.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 150px;
  border: 2px solid rgb(166, 166, 166);
  border-radius: 30px;
  padding-bottom: 20px;
}
.blockTitle {
  color: rgb(225, 5, 0);
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 23%;
}
.blockContent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.linkWrapper {
  margin-top: 10px;
}
.link {
  color: rgb(225, 5, 0);
}
