.step3Wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
}
.universeAndCPPWrapper {
  display: flex;
  justify-content: space-between;
  /* width: 1450px; */
  height: 54px;
  /* margin: 0 auto;
  padding-top: 10px; */
  padding-bottom: 10px;
}
.universeWrapper {
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 54px;
}
.nextButtonWrapper {
  /* width: 1450px; */
  display: flex;
  justify-content: flex-start;
  padding-left: 150px;
  /* margin: 0 auto; */
}
.topButtonsWrapper {
  width: 1450px;
  display: flex;
  justify-content: space-between;
}