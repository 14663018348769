.blockContent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.linkWrapper {
  margin-top: 10px;
}
.link {
  color: rgb(225, 5, 0);
}
