.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 150px;
  border: 2px solid rgb(166, 166, 166);
  border-radius: 30px;
  padding-bottom: 20px;
  /* padding-top: 20px; */
}
.blockTitle {
  color: rgb(225, 5, 0);
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -6%;
  left: 38%;
}
.blockContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.checkboxWrapper {
  min-height: 40px;
  display: flex;
  margin-left: 40px;
}
.cityXSelectWrapper {
  display: none;
  position: absolute;
  top: 57%;
  left: 29%;
}
.visible {
  display: block;
}
