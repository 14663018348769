.tableButton {
  background-color: white;
  min-height: 50px;
  min-width: 250px;
  border: none;
  color: black;
  font-size: 130%;
  white-space: pre;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.tableButton:hover {
  background-color: rgb(200, 200, 209);
  border-color: rgb(200, 200, 209);
}
.tableButton:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
}
.title {
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.redTitle {
  color: rgb(225, 5, 0);
  padding: 10px;
}
.redTitleDisabled {
  color: rgb(160, 111, 110);
  padding: 10px;
}
.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background-color: rgb(115, 143, 162); */
  background-color: #738fa2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
