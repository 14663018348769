.optimizationCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  min-height: 210px;
  max-height: 550px;
  min-width: 450px;
  background-color: white;
  color: black;
  font-size: 18px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  position: absolute;
  top: 60px;
  z-index: 999;
  overflow: auto
}
.modal {
  background-color: rgba(0, 0, 0, 0.432);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 999;
}
.active {
  opacity: 1;
  pointer-events: all;
  width: 100%;
  height: 100%;
}
.titleBlock {
  display: flex;
  justify-content: space-between;
}
.closeButton {
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 25px;
}
.budgetLimitsTableWrapper {
  display: flex;
  justify-content: space-between;
}
.optimizationDataTable {
  display: flex;
  flex-direction: column;
}
.optimizationDataRow {
  display: flex;
}
.optimizationDataTableCell {
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 140px;
  padding: 5px;
}
.optBtnsWrapper {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1500px) {
  .optimizationCard {  max-height: 800px; }
}